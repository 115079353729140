import React, { useState } from "react";

const CryptoPrice = () => {
  const [state, setState] = useState<any>({
    keyName: "",
    keyValue: "",
    logo: "",
  });

  const [keys, setKeys] = useState<any>([
    {
      id: 0,
      keyName: "Bitcoin",
      keyValue: "100",
      logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
    },
    {
      id: 1,
      keyName: "Ethereum",
      keyValue: "500",
      logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029",
    },
  ]);

  // const [, setFiatKeys] = useState<any>([
  //   {
  //     id: 0,
  //     keyName: "NGN",
  //     keyValue: "100",
  //   },
  //   {
  //     id: 1,
  //     keyName: "USD",
  //     keyValue: "500",
  //   },
  // ]);

  const handleUpdateApiKey = (keyId: number) => {
    setKeys((prevKeys: any) =>
      prevKeys.map((key: any) =>
        key.id === keyId ? { ...key, keyValue: state.keyValue } : key
      )
    );
    setState({
      keyName: "",
      keyValue: "",
      logo: "",
    });
  };

  // const handleUpdateFiatApiKey = (keyId: number) => {
  //   setFiatKeys((prevKeys: any) =>
  //     prevKeys.map((key: any) =>
  //       key.id === keyId ? { ...key, keyValue: state.keyValue } : key
  //     )
  //   );
  //   setState({
  //     keyName: "",
  //     keyValue: "",
  //     logo: "",
  //   });
  // };

  return (
    // <Layout>
    <div>
      {keys?.map((apiKey: any) => (
        <div
          key={apiKey.id}
          className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
        >
          <p className="text-lg font-bold basis-3/12 flex items-center gap-4">
            <img src={apiKey.logo} width={"20px"} />
            {apiKey?.keyName}
          </p>

          <div className="basis-3/12 ">
            <span className="block text-xs">Former Value</span>
            <span className="font-bold text-[16px]">{apiKey?.keyValue}</span>
          </div>
          <div className="basis-3/12 ">
            <span className="block text-xs">Current Value</span>
            <span className="font-bold text-[16px]">{apiKey?.keyValue}</span>
          </div>
          <div className="flex justify-ent items-center gap-4">
            <input
              onChange={(e) => setState({ ...state, keyValue: e.target.value })}
              value={state.keyValue}
              type="number"
              placeholder="Update Limit"
              className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
              onClick={() => handleUpdateApiKey(apiKey.id)}
              className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
            >
              Update
            </button>
          </div>
        </div>
      ))}
    </div>

    // : fiatKeys?.map((apiKey: any) => (
    //     <div
    //       key={apiKey.id}
    //       className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
    //     >
    //       <p className="text-lg font-bold basis-3/12 flex items-center gap-4">
    //         {apiKey?.keyName}
    //       </p>

    //       <div className="basis-3/12 ">
    //         <span className="block text-xs">Former Value</span>
    //         <span className="font-bold text-[16px]">
    //           {apiKey?.keyValue}
    //         </span>
    //       </div>
    //       <div className="basis-3/12 ">
    //         <span className="block text-xs">Current Value</span>
    //         <span className="font-bold text-[16px]">
    //           {apiKey?.keyValue}
    //         </span>
    //       </div>
    //       <div className="flex justify-ent items-center gap-4">
    //         <input
    //           onChange={(e) =>
    //             setState({ ...state, keyValue: e.target.value })
    //           }
    //           value={state.keyValue}
    //           type="number"
    //           placeholder="Update Limit"
    //           className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //         />
    //         <button
    //           onClick={() => handleUpdateFiatApiKey(apiKey.id)}
    //           className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
    //         >
    //           Update
    //         </button>
    //       </div>
    //     </div>
    //   ))}
    // </Layout>
  );
};

export default CryptoPrice;
