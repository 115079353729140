import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Select from "react-select";

const EditCoin = ({ isOpen, setIsOpen, data, setData, selected }: any) => {
  // const [selected, ] = useState<string>("crypto");
  // setSelected('crypto')

  const options = [
    { value: "BTC", label: "BTC" },
    { value: "ETH", label: "ETH" },
    { value: "BNB", label: "BNB" },
  ];
  const fiatOptions = [
    { value: "USD", label: "USD" },
    { value: "PKR", label: "PKR" },
    { value: "AED", label: "AED" },
  ];

  const handleOptionChange = (e: any) => {
    const value = e.target.value;

    const isValueInArray = data?.withdrawn.includes(value);

    if (isValueInArray) {
      const newArray = data?.withdrawn.filter((item: any) => item !== value);
      setData({ ...data, withdrawn: newArray });
    } else {
      setData({ ...data, withdrawn: [...data?.withdrawn, value] });
    }
  };

  const handleWithdrawChange = (value: string) => {
    const isValueInArray = data?.withdrawn?.includes(value);

    if (isValueInArray) {
      const newArray = data?.withdrawn?.filter((item: any) => item !== value);
      setData({ ...data, withdrawn: newArray });
    } else {
      setData({ ...data, withdrawn: [...(data?.withdrawn || []), value] });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {selected === "crypto"
                          ? "Edit Crypto Coin/Token"
                          : "Edit Fiat Currency"}
                      </Dialog.Title>
                      <div className="mt-4">
                        {selected === "crypto" ? (
                          <div className="mt-2">
                            <label
                              htmlFor="coinName"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Coin/Token name
                            </label>
                            <div className="mt-2">
                              <input
                                id="coinName"
                                name="coin_name"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.name}
                                onChange={(e: any) =>
                                  setData({ ...data, name: e.target.value })
                                }
                              />
                            </div>
                            <label
                              htmlFor="contract_address"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              EVM Token Contract Address
                            </label>
                            <div className="mt-2">
                              <input
                                id="contract_address"
                                name="contract_address"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.address}
                                onChange={(e: any) =>
                                  setData({ ...data, address: e.target.value })
                                }
                              />
                            </div>
                            <label
                              htmlFor="symbol"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Symbol
                            </label>
                            <div className="mt-2">
                              <input
                                id="symbol"
                                name="symbol"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.symbol}
                                onChange={(e: any) =>
                                  setData({ ...data, symbol: e.target.value })
                                }
                              />
                            </div>
                            <label
                              htmlFor="gecko_id"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Coingecko id
                            </label>
                            <div className="mt-2">
                              <input
                                id="gecko_id"
                                name="gecko_id"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.coinGeckoId}
                                onChange={(e: any) =>
                                  setData({
                                    ...data,
                                    coinGeckoId: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <label
                              htmlFor="logo"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Logo
                            </label>
                            <div className="mt-2">
                              <input
                                id="logo"
                                name="logo"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.logo}
                                onChange={(e: any) =>
                                  setData({ ...data, logo: e.target.value })
                                }
                              />
                            </div>

                            <div className="mt-2">
                              <label
                                htmlFor="coinName"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                How can this token be withdrawn?
                              </label>
                              <div className="mt-2 flex gap-5">
                                <div className="mt-2 flex gap-2 items-center ">
                                  <input
                                    id="cash_check"
                                    name="cash"
                                    type="checkbox"
                                    checked={data?.withdrawn?.includes("cash")}
                                    onChange={() =>
                                      handleWithdrawChange("cash")
                                    }
                                    required
                                  />
                                  <label
                                    htmlFor="cash_check"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Cash
                                  </label>
                                </div>
                                <div className="mt-2 flex gap-2 items-center ">
                                  <input
                                    id="bank_check"
                                    name="logo"
                                    type="checkbox"
                                    checked={data?.withdrawn?.includes("bank")}
                                    onChange={() =>
                                      handleWithdrawChange("bank")
                                    }
                                    required
                                  />
                                  <label
                                    htmlFor="bank_check"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Bank
                                  </label>
                                </div>
                                <div className="mt-2 flex gap-2 items-center ">
                                  <input
                                    id="mobile_check"
                                    name="logo"
                                    type="checkbox"
                                    checked={data?.withdrawn?.includes(
                                      "mobile"
                                    )}
                                    onChange={() =>
                                      handleWithdrawChange("mobile")
                                    }
                                    required
                                  />
                                  <label
                                    htmlFor="mobile_check"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Mobile Money
                                  </label>
                                </div>
                              </div>
                            </div>

                            <label
                              htmlFor="swap"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Currencies you can swap
                            </label>
                            <div className="mt-2">
                              <Select options={options} isMulti />
                            </div>
                            <label
                              htmlFor="cex-check-label"
                              className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                            >
                              How it can be swapped?
                            </label>
                            <div className="mt-2 flex gap-2 items-center">
                              <input
                                id="cex_check"
                                name="logo"
                                type="checkbox"
                                checked={data?.swappedOn || false}
                                onChange={(e: any) =>
                                  setData({
                                    ...data,
                                    swappedOn: e.target.checked,
                                  })
                                }
                                required
                              />
                              <label
                                htmlFor="cex_check"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                CEX (Binance Exchange)
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="mt-2">
                            <label
                              htmlFor="coinName"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Coin/Token name
                            </label>
                            <div className="mt-2">
                              <input
                                id="coinName"
                                name="coin_name"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                value={data?.name}
                                onChange={(e: any) =>
                                  setData({ ...data, name: e.target.value })
                                }
                              />
                            </div>
                            <label
                              htmlFor="contract_address"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Currency Selection
                            </label>
                            <div className="mt-2">
                              <Select
                                options={fiatOptions}
                                isMulti
                                defaultValue={fiatOptions.find(
                                  (option) => option.value === data?.currency
                                )}
                                onChange={(selectedOption: any) =>
                                  setData({
                                    ...data,
                                    currency: selectedOption?.value || "",
                                  })
                                }
                              />
                            </div>
                            <label
                              htmlFor="how_token"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              How can this token be withdrawn?
                            </label>
                            <div className="mt-2 flex gap-4">
                              <div className="flex gap-2">
                                <input
                                  id="cash"
                                  name="how_withdraw"
                                  type="checkbox"
                                  checked={data?.withdrawn?.includes("Cash")}
                                  onChange={(e) => handleOptionChange(e)}
                                  required
                                  value={"Cash"}
                                />
                                <label
                                  htmlFor="cash"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Cash
                                </label>
                              </div>
                              <div className="flex gap-2">
                                <input
                                  id="bank"
                                  name="how_withdraw"
                                  type="checkbox"
                                  required
                                  value={"Bank"}
                                  checked={data?.withdrawn?.includes("Bank")}
                                  onChange={(e) => handleOptionChange(e)}
                                />
                                <label
                                  htmlFor="bank"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Bank
                                </label>
                              </div>
                              <div className="flex gap-2">
                                <input
                                  id="mobile_money"
                                  name="how_withdraw"
                                  type="checkbox"
                                  required
                                  value={"Mobile Money"}
                                  checked={data?.withdrawn?.includes(
                                    "Mobile Money"
                                  )}
                                  onChange={(e) => handleOptionChange(e)}
                                />
                                <label
                                  htmlFor="mobile_money"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Mobile Money
                                </label>
                              </div>
                            </div>

                            <label
                              htmlFor="swap"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Currencies you can swap
                            </label>
                            <div className="mt-2">
                              <Select options={options} isMulti />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-cyan-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-cyan-800 sm:ml-3 sm:w-auto ring-1 ring-inset ring-cyan-800"
                    onClick={() => setIsOpen(false)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditCoin;
