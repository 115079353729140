import React, { useState } from "react";

interface Option {
  value: string;
  label: string;
}

const options: any = [
  { value: "Id", label: "ID Card" },
  { value: "License", label: "Drivers License" },
  { value: "Passport", label: "Passport" },
];

const ManageNotesDocs = () => {
  const [activeTab, setActiveTab] = useState<string>("KYC1");
  const [kyc1Checked, setKyc1Checked] = useState(false);
  const [kyc2Checked, setKyc2Checked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const [keys, setKeys] = useState<any>([
    {
      id: 1,
      keyName: "KYC 1 Limit",
      keyValue: "500",
      swapLimit: "100",
      withdrawLimit: "140",
    },
    {
      id: 2,
      keyName: "KYC 2 Limit",
      keyValue: "700",
      swapLimit: "200",
      withdrawLimit: "740",
    },
  ]);

  const handleKyc1Change = () => {
    setKyc1Checked(!kyc1Checked);
    if (!kyc1Checked) {
      setKyc2Checked(false);
    }
  };

  const handleKyc2Change = () => {
    setKyc2Checked(!kyc2Checked);
  };

  const handleInputChange = (event: any, keyId: any, field: any) => {
    const newKeys = keys.map((key: any) =>
      key.id === keyId ? { ...key, [field]: event.target.value } : key
    );
    setKeys(newKeys);
  };

  const handleUpdate = (keyId: any) => {
    // Implement logic to update data
    console.log("Update key with id:", keyId);
  };

  const handleCheckboxChange = (value: any) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  return (
    <div>
      <ul className="flex flex-wrap items-center gap-3 mb-4 mt-8">
        <li>
          <div
            onClick={() => setActiveTab("KYC1")}
            className={` ${
              activeTab === "KYC1" ? "text-black-900" : "text-black-900/20"
            } text-xl cursor-pointer`}
          >
            KYC1
          </div>
        </li>
        <li>
          <div
            onClick={() => setActiveTab("KYC2")}
            className={` ${
              activeTab === "KYC2" ? "text-black-900" : "text-black-900/20"
            } text-xl cursor-pointer`}
          >
            KYC2
          </div>
        </li>
      </ul>
      <div className="bg-cyan-300 rounded-lg p-5">
        <div className="flex items-center justify-between">
          <div className="text-left flex items-center justify-start mr-4 ">
            {activeTab === "KYC1" && (
              <label className="inline-flex items-center cursor-pointer">
                <span className="mr-3  font-medium ">KYC 1</span>
                <input
                  type="checkbox"
                  checked={kyc1Checked}
                  onChange={handleKyc1Change}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-800"></div>
              </label>
            )}
            {activeTab === "KYC2" && (
              <label className="inline-flex items-center cursor-pointer">
                <span className="mr-3  font-medium ">KYC 2</span>
                <input
                  type="checkbox"
                  checked={!kyc1Checked ? false : kyc2Checked}
                  onChange={handleKyc2Change}
                  disabled={!kyc1Checked}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-800"></div>
              </label>
            )}
          </div>
          {activeTab === "KYC1" && (
            <div className="mt-2">
              <label
                htmlFor="type"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                KYC Document Types:
              </label>
              <div className="mt-2 flex gap-4">
                {options.map((option: any) => (
                  <div
                    key={option.value}
                    className="flex items-center cursor-pointer mr-1"
                  >
                    <label className="mr-1" htmlFor={option.value}>
                      {option.label}
                    </label>
                    <input
                      id={option.value}
                      type="checkbox"
                      value={option.value}
                      checked={selectedOptions.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          {activeTab === "KYC1" && (
            <div className="mt-4">
              <label
                htmlFor="note"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Kyc Note
              </label>
              <div className="mt-2">
                <textarea
                  id="note"
                  name="note"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}

          {activeTab === "KYC2" && (
            <>
              <div className="mt-8">
                <label
                  htmlFor="notes"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  KYC Note
                </label>
                <div className="mt-2">
                  <textarea
                    id="notes"
                    name="notes"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="mt-8">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Instructions
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className=" py-3 sm:flex sm:flex-row-reverse  gap-3">
          <button className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-4 py-2 sm:text-sm">
            Save
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Cancel
          </button>
        </div>
      </div>
      {
        activeTab === "KYC1" ? (
          // keys?.map((apiKey: any) => (
          <div
            key={keys[0].id}
            className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
          >
            <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
              {keys[0]?.keyName}
            </p>
            <div className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}>
              <div className=" text-right">
                <span className="block text-xs ">Swap Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {keys[0]?.swapLimit}
                </span>
              </div>
              <input
                type="number"
                placeholder="Enter new Swap Limit"
                value={keys[0].swapLimit}
                onChange={(e) => handleInputChange(e, keys[0].id, "swapLimit")}
                className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => handleUpdate(keys[0].id)}
                className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
            <div
              className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
            >
              <div className="text-right">
                <span className="block text-xs ">Withdraw Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {keys[0]?.withdrawLimit}
                </span>
              </div>
              <input
                type="number"
                placeholder="Enter new fee"
                value={keys[0].withdrawLimit}
                onChange={(e) =>
                  handleInputChange(e, keys[0].id, "withdrawLimit")
                }
                className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => handleUpdate(keys[0].id)}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          // ))
          // keys?.map((apiKey: any) => (
          <div
            key={keys[1].id}
            className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
          >
            <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
              {keys[1]?.keyName}
            </p>
            <div className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}>
              <div className=" text-right">
                <span className="block text-xs ">Swap Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {keys[1]?.swapLimit}
                </span>
              </div>
              <input
                type="number"
                placeholder="Enter new Swap Limit"
                value={keys[1].swapLimit}
                onChange={(e) => handleInputChange(e, keys[1].id, "swapLimit")}
                className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => handleUpdate(keys[1].id)}
                className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
            <div
              className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
            >
              <div className="text-right">
                <span className="block text-xs ">Withdraw Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {keys[1]?.withdrawLimit}
                </span>
              </div>
              <input
                type="number"
                placeholder="Enter new fee"
                value={keys[1].withdrawLimit}
                onChange={(e) =>
                  handleInputChange(e, keys[1].id, "withdrawLimit")
                }
                className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => handleUpdate(keys[1].id)}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
          </div>
        )
        // ))
      }
      {/* </div> */}
    </div>
  );
};

export default ManageNotesDocs;
