import Layout from "layout";
import React, { useState } from "react";

const APIs = () => {
  const [state, setState] = useState<any>({
    keyName: "",
    keyValue: "",
  });

  const [keys, setKeys] = useState<any>([
    { id: 0, keyName: "Binance Key 1", keyValue: "12343rfgtrtyhrt" },
  ]);

  const handleUpdateApiKey = (keyId: number) => {
    setKeys((prevKeys: any) =>
      prevKeys.map((key: any) =>
        key.id === keyId ? { ...key, keyValue: state.keyValue } : key
      )
    );
    setState({
      keyName: "",
      keyValue: "",
    });
  };

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h1 className="text-xl font-bold">API Keys</h1>

          {/* Existing API Keys Section */}
          {keys.map((apiKey: any) => (
            <div
              key={apiKey.id}
              className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
            >
              <p className="text-lg font-bold basis-5/12 flex items-center gap-4">
                {apiKey?.keyName}
              </p>

              <div className="basis-2/12 text-right">
                <span className="block text-xs">Value</span>
                <span className="font-bold text-[16px]">
                  {apiKey?.keyValue}
                </span>
              </div>
              <input
                onChange={(e) =>
                  setState({ ...state, keyValue: e.target.value })
                }
                value={state.keyValue}
                type="number"
                placeholder="Update key"
                className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                onClick={() => handleUpdateApiKey(apiKey.id)}
                className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default APIs;
