import Layout from "layout";
import React, { useState } from "react";
import CryptoPrice from "views/cryptoPrice";

const LimitManagement = () => {
  const [activeTab, setActiveTab] = useState<string>("Limits");
  // const [state, setState] = useState<any>({
  //   keyName: "",
  //   keyValue: "",
  //   logo: "",
  // });

  const [keys, setKeys] = useState<any>([
    {
      id: 0,
      keyName: "Email Validation Limit",
      swapLimit: "200",
      withdrawLimit: "300",
    },
  ]);

  const handleInputChange = (event: any, keyId: any, field: any) => {
    const newKeys = keys.map((key: any) =>
      key.id === keyId ? { ...key, [field]: event.target.value } : key
    );
    setKeys(newKeys);
  };

  const handleUpdate = (keyId: any) => {
    // Implement logic to update data
    console.log("Update key with id:", keyId);
  };

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("Limits")}
                className={` ${
                  activeTab === "Limits"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Limits
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("Crypto Limits")}
                className={` ${
                  activeTab === "Crypto Limits"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Crypto Limits
              </div>
            </li>
          </ul>

          {activeTab === "Crypto Limits" ? (
            <CryptoPrice />
          ) : (
            keys?.map((apiKey: any) => (
              <div
                key={apiKey.id}
                className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
              >
                <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
                  {apiKey?.keyName}
                </p>
                <div
                  className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}
                >
                  <div className=" text-right">
                    <span className="block text-xs ">Swap Limit</span>
                    <span className="font-bold text-[14px]">
                      {/* {getFee("withdraw_fiat_fee")}% */}
                      {apiKey?.swapLimit}
                    </span>
                  </div>
                  <input
                    type="number"
                    placeholder="Enter new Swap Limit"
                    value={apiKey.swapLimit}
                    onChange={(e) =>
                      handleInputChange(e, apiKey.id, "swapLimit")
                    }
                    className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    onClick={() => handleUpdate(apiKey.id)}
                    className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
                  >
                    Update
                  </button>
                </div>
                <div
                  className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
                >
                  <div className="text-right">
                    <span className="block text-xs ">Withdraw Limit</span>
                    <span className="font-bold text-[14px]">
                      {/* {getFee("withdraw_fiat_fee")}% */}
                      {apiKey?.withdrawLimit}
                    </span>
                  </div>
                  <input
                    type="number"
                    placeholder="Enter new fee"
                    value={apiKey.withdrawLimit}
                    onChange={(e) =>
                      handleInputChange(e, apiKey.id, "withdrawLimit")
                    }
                    className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    onClick={() => handleUpdate(apiKey.id)}
                    className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
                  >
                    Update
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LimitManagement;
