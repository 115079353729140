import { IconEye } from "@tabler/icons-react";
import CoolingPeriod from "components/coolingPeriod";
import ChakraUIMenu from "components/menu";
import Layout from "layout";
import React, { useState } from "react";
import ManageNotesDocs from "views/manageNotesDocs";

const kycTypes = ["All", "Kyc1", "Kyc2"];

const Kyc = () => {
  const [selectedKycType, setSelectedKycType] = useState("All");

  const [activeTab, setActiveTab] = useState<string>("KYC");

  // const [kyc1Checked, setKyc1Checked] = useState(false);
  // const [kyc2Checked, setKyc2Checked] = useState(false);

  const data = [
    {
      userId: 1,
      adminApproval: "Approved",
      updatedAt: "2017-04-20",
      name: "test",
      email: "test@example.com",
      kycTier: 1,
      occupation: "Employ",
      description: "Lorem Ipsum",
      contactNumber: "+23456789763",
      address: "123 Main Street",
      country: "United States",
      documentType: "Passport",
      sourceOfFund:
        "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_640.jpg",
    },
    {
      userId: 2,
      adminApproval: "Rejected",
      updatedAt: "2019-04-20",
      name: "test2",
      email: "test2@example.com",
      kycTier: 2,
      occupation: "Bank",
      contactNumber: "+23456789763",
      address: "123 Main Street",
      country: "United States",
      description: "Lorem Ipsum",
      sourceOfFund:
        "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_640.jpg",
    },
  ];

  // const handleKyc1Change = () => {
  //   setKyc1Checked(!kyc1Checked);
  //   if (!kyc1Checked) {
  //     setKyc2Checked(false);
  //   }
  // };

  // const handleKyc2Change = () => {
  //   setKyc2Checked(!kyc2Checked);
  // };

  const filteredData =
    kycTypes.includes(selectedKycType) && selectedKycType !== "All"
      ? data.filter(
          (item) => item.kycTier.toString() === selectedKycType.slice(-1)
        )
      : data;

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("KYC")}
                className={` ${
                  activeTab === "KYC" ? "text-black-900" : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                KYC
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("Settings")}
                className={` ${
                  activeTab === "Settings"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Settings
              </div>
            </li>
          </ul>
          {activeTab === "KYC" ? (
            <>
              <div className="flex items-center justify-between mb-1 mt-4">
                <div className="">
                  <label htmlFor="filter" className="mr-2 text-lg">
                    Filter
                  </label>
                  <select
                    id="filter"
                    className="text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-1.5 px-3"
                    value={selectedKycType}
                    onChange={(e) => setSelectedKycType(e.target.value)}
                  >
                    {kycTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="overflow-auto mt-4">
                <div className="w-[976px] h-[85dvh]">
                  <div className="table-wrapper max-h-[38rem] overflow-auto">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                            Time
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Name
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Email
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Level
                          </th>
                          <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {isLoading ? ( */}
                        {/* <Spinner /> */}
                        {/* ) : data?.length === 0 ? ( */}
                        {/* "No Data Available" */}
                        {/* ) : ( */}
                        {filteredData?.map((data, idx) => (
                          <TableRow
                            key={data?.userId}
                            data={data}
                            index={idx}
                          />
                        ))}
                        {/* )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ManageNotesDocs />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Kyc;

function TableRow({ data, index }: { data: any; index: number }) {
  const [isVisible, setIsVisible] = useState(false); // Change initial state to `false`
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const handleClick = (option: string) => {
    if (option === "Approve") {
      console.log("Approve action...");
    } else if (option === "Reject") {
      if (data?.kycTier === 1) {
        setIsCreateOpen(true);
      } else {
        console.log("tier2");
      }
    } else if (option === "Request for Update") {
      // Action for Request for Update
      console.log("Request for Update action...");
    }
  };

  const menuOptions = ["Approve", "Reject"];
  if (data?.kycTier === 1) {
    menuOptions.push("Request for Update");
  }

  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(data?.updatedAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30 text-left">
          <p className="text-black-900 text-xs font-semibold">{data?.name}</p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">{data?.email}</p>
        </td>

        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center flex gap-2">
            Level {data?.kycTier}
          </p>
        </td>

        <td className="rounded-r-lg px-0 border border-black-800/30 border-l-0 py-5 flex justify-center">
          <div className="flex gap-4">
            {/* <i
              className="fa fa-eye text-red hover:text-[19px]"
              style={{ cursor: "pointer", color: "red" }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
            ></i> */}
            <IconEye
              cursor={"pointer"}
              onClick={() => setIsVisible(!isVisible)}
            />
            <ChakraUIMenu options={menuOptions} onClick={handleClick} />
          </div>
        </td>
        <CoolingPeriod isOpen={isCreateOpen} setIsOpen={setIsCreateOpen} />
      </tr>
      {isVisible && (
        <tr className="bg-cyan-300 ">
          <td colSpan={5} className=" px-2 rounded-r-lg">
            <div className="px-3 pt-6" key={index}>
              <h2 className="text-lg font-bold mb-4">User Details</h2>
              <div className="flex items-center justify-between mb-5">
                <div className="flex gap-2 items-center">
                  {/* <img
                    src="Portrait-Placeholder.jpg"
                    className="h-[30px] rounded-[20px]"
                  /> */}
                  <p>{data?.name}</p>
                </div>
                <p>{data?.email}</p>
                <p>{data?.contactNumber}</p>
                <p>{data?.address}</p>
                <p>{data?.country}</p>
              </div>
              {data.kycTier === 1 && (
                <>
                  <div className="flex items-center gap-3 mb-4">
                    <h2 className="text-lg font-bold ">Document Type:</h2>
                    <p className="font-semibold">{data?.documentType}</p>
                  </div>
                  <div
                    className="flex justify-center gap-4"
                    // style={{
                    //   maxHeight: "450px",
                    //   maxWidth: "450px",
                    //   marginLeft: "auto",
                    //   marginRight: "auto",
                    // }}
                  >
                    <div>
                      <p className="text-lg">Front:</p>

                      <img
                        src={
                          data?.sourceOfFund && data?.sourceOfFund === ""
                            ? "Placeholder.png"
                            : data?.sourceOfFund
                        }
                        className="my-2 rounded-xl cursor-pointer"
                        alt="document"
                      />
                    </div>
                    <div>
                      <p className="text-lg">Back:</p>

                      <img
                        src={
                          data?.sourceOfFund && data?.sourceOfFund === ""
                            ? "Placeholder.png"
                            : data?.sourceOfFund
                        }
                        className="my-2 rounded-xl cursor-pointer"
                        alt="document"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
