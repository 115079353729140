import CreateNotification from "components/createNotification";
import Layout from "layout";
import React, { useState } from "react";

const Notifications = () => {
  const data = [
    {
      userId: 1,
      title: "Announcement 1",
      message: "Lorem Ipsum Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      updatedAt: "2023-04-20",
    },
    {
      userId: 2,
      title: "Announcement 2",
      message: "Lorem Ipsum Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      updatedAt: "2023-01-05",
    },
    {
      userId: 3,
      title: "Announcement 3",
      message: "Lorem Ipsum Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      updatedAt: "2023-12-08",
    },
    {
      userId: 4,
      title: "Announcement 4",
      message: "Lorem Ipsum Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      updatedAt: "2023-01-02",
    },
  ];

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div className={"text-black-900 text-xl cursor-pointer"}>
                Notifications
              </div>
            </li>
          </ul>
          <div className="text-right">
            <button
              onClick={() => setIsCreateOpen(true)}
              className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
            >
              Create
            </button>
          </div>
          <div className="overflow-auto mt-4">
            <div className="w-[976px] h-[85dvh]">
              <div className="table-wrapper max-h-[38rem] overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                        Title
                      </th>

                      <th className="py-5 px-5 border-y border-black-800/30 text-left">
                        Message
                      </th>
                      {/* <th className="py-5 px-5 border-y border-black-800/30 text-left">
                        Description
                      </th> */}
                      <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-center">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {isLoading ? ( */}
                    {/* <Spinner /> */}
                    {/* ) : data?.length === 0 ? ( */}
                    {/* "No Data Available" */}
                    {/* ) : ( */}
                    {data?.map((data, idx) => (
                      <TableRow key={data?.userId} data={data} index={idx} />
                    ))}
                    {/* )} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <CreateNotification
            isOpen={isCreateOpen}
            setIsOpen={setIsCreateOpen}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;

function TableRow({ data, index }: { data: any; index: number }) {
  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-6 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs font-semibold">{data?.title}</p>
        </td>

        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            {data?.message}
          </p>
        </td>

        {/* <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center flex gap-2">
            {data?.description}
          </p>
        </td> */}

        <td className="rounded-r-lg px-0 border border-black-800/30 border-l-0 py-8 flex justify-center">
          <div className="flex gap-4">
            <p className="text-black-900 text-xs ml-3">
              {new Date(data?.updatedAt).toLocaleDateString("us", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        </td>
      </tr>
    </>
  );
}
